.ContactPage {
    width: 80% !important;
    margin: auto;

    .contactRow {
        border-top: 2px solid black;
        cursor: pointer;
        height: 265px;
        display: flex;
        justify-content: space-between;

        a {
            text-decoration: none;
            color: black;
        }

        .contactTitle {
            display: block;
        }

        .contactStack,
        a {
            width: 100%;
            height: 100%;
            justify-content: center;
        }

        .contactStack {
            justify-content: space-between;
        }

        &:hover {
            background-color: black;
            color: white;

            a {
                color: white;
            }

            .hoverContact {
                display: block;

            }

            .contactTitle {
                display: none;
            }
        }

        .hoverContact {
            display: none;
            text-align: center;
            width: 100%;
        }
    }

    .jotFormFrame {
        min-height: 700px !important;

        margin-bottom: 100px;

        .supernova .form-all {
            box-shadow: none !important;
            border: 2px solid black !important;
        }
    }



}