#loader-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: "Futura", 'Roboto', 'Helvetica', sans-serif;

    #EstebanIncLogo {
        animation: circleScale 3s infinite;
    }

    .progress-4 {
        width: 120px;
        height: 20px;
        -webkit-mask: linear-gradient(90deg, #000 70%, #0000 0) 0/20%;
        background: linear-gradient(#000 0 0) 0/0% no-repeat #ddd;
        animation: p4 2s infinite steps(6);
    }


}




@keyframes p4 {
    100% {
        background-size: 120%
    }
}

@keyframes circleScale {
    0% {
        transform: scale(.75)
    }

    50% {
        transform: scale(1)
    }

    100% {
        transform: scale(.757)
    }
}