.AboutPage {
    width: 75%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    h2 {
        font-size: 50px;

    }

    h3 {
        font-size: 65px;
        text-transform: uppercase;

    }

    p {
        font-size: 32px;
        width: 75%;
        margin: auto;
        margin-bottom: 50px;
    }

    .aboutServices {
        width: 95%;
        margin: auto;
        padding: 0 50px;

        a {
            display: block;
            height: 100% !important;

        }
    }

    .serviceChip {
        font-size: 62px;
        font-family: 'Futura';
        padding: 65px;
        border: 2px solid black;
        border-radius: 65px;

        cursor: pointer;
        min-width: 150px;

        .chipHover {
            display: none;
        }

        &:hover {
            background-color: black;

            .chipTitle {
                display: none;
            }

            .chipHover {
                display: block;
                color: white;
            }
        }
    }
}