@media (max-width: 1440px) {
    .Home {

        .gallery {
            min-height: 0 !important;

            .gallery-box {
                height: 400px !important;
                width: 300px !important;
                min-width: 300px !important;
            }
        }


        .resumeList {
            li {
                .arrow {
                    svg {
                        font-size: 32px !important;
                    }
                }
            }
        }

        #services .serviceCard {
            height: auto !important;
        }
    }

    .menuNav {
        width: 90%;

        #menuText {
            font-size: 65px !important;
            width: 100% !important;
        }

        #logoMain {
            margin-top: 15px !important;
        }

    }

    .copyright {
        font-size: 64px !important;
        line-height: 68px !important;
    }

    .ContactPage {
        h2 {
            font-size: 72px;
        }

        h3 {
            font-size: 55px;
        }

        .contactRow {}
    }

    .AboutPage {
        .serviceChip {
            font-size: 55px;
        }
    }

}



@media (max-width: 1200px) {

    .Services,
    .ContactPage {
        width: 95% !important;
    }

    .Home {
        padding-top: 10%;

        .gallery {

            .gallery-box {
                height: 350px !important;
                width: 250px !important;
                min-width: 250px !important;
            }
        }

        .subHeader {
            height: 255px !important;
        }

        .home-section {
            h2 {
                &.word {
                    font-size: 6rem !important;
                    line-height: 100px !important;

                    &.Multidisciplinary {
                        font-size: 90px !important;
                    }

                    &.Knife {
                        font-size: 90px !important;
                    }

                    &.smallHeader {
                        font-size: 45px !important;

                    }
                }


            }

            h3 {
                font-size: 55px;
            }


        }

        .quoteContainer {
            min-height: 65vh;
        }

        .bigBanner {
            font-size: 68px !important;
            line-height: initial;
        }

        .jackCard {
            top: 0;
            left: -150px;
            height: 400px;
            width: 400px;
        }

        .queen {
            bottom: -110px;
            right: -150px !important;
            height: 400px;
            width: 400px;
        }


    }

    .menuNav {
        #menuText {
            font-size: 45px !important;
        }

        .mobileHeaderMenu {
            display: block !important;
        }

        .enable-animation {
            display: none;
        }

        .currentStrip {
            margin-bottom: 50px;
        }

    }

    .resumeList {

        display: block;

        li {
            margin: 0 !important;
            text-align: center !important;
            width: 50% !important;
        }


    }

    .copyright {
        font-size: 42px !important;
        line-height: 45px !important;
        top: auto !important;
        bottom: 0 !important;
    }

    .ContactPage {
        .contactRow {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            align-content: center !important;
            pointer-events: none;
        }

        .contactStack {
            justify-content: center !important;
            flex-direction: column !important;
            background-color: white !important;
            color: black !important;

            .contactTitle {
                font-size: 65px !important;
                margin: 0 !important;
                display: block !important;
            }

            .hoverContact {
                font-size: 48px !important;
                display: none !important;
            }
        }

        .outlinedBtn {
            display: block !important;
            font-size: 18px !important;
        }
    }



    .Services {
        width: 100% !important;

        h2 {
            font-size: 65px !important;
        }

        .serviceBox {
            height: auto !important;
            pointer-events: none;

            .serviceList {
                display: block;
            }

            .serviceProj {
                display: none !important;

                .projTitle {
                    display: block !important;
                    position: relative !important;
                    // top: 100% !important;
                    // width: 100%;
                    // transform: translate(-50%, 0);
                }
            }

        }
    }

    .menuNav {
        height: 105px !important;
        padding: 0 !important;
    }

    #logoMain {
        height: 50px;
        width: 50px;
    }

    .smallHeader {
        font-size: 60px !important;
    }

    .Home {
        .bigBanner {
            font-size: 55px !important;
            line-height: initial;
        }

        .home-section {
            h2 {
                &.word {
                    font-size: 6em !important;
                    line-height: 90px;

                    &.Multidisciplinary {
                        font-size: 90px !important;
                    }

                    &.Knife {
                        font-size: 90px !important;
                    }
                }
            }
        }

        .subHeader {
            height: 250px;
        }


        #services {
            .serviceCard {
                pointer-events: none;
                padding: 0 !important;
                margin: 0 !important;


                .serviceLines {
                    display: block;
                }
            }


            .pin-spacer {
                display: none !important;
            }

            .bg-red-500 {
                margin-bottom: 100px;
            }

            h4 {
                font-size: 3rem;

            }

            .mobileTitle {
                display: block !important;
            }


        }

        .gallerySection {
            display: none !important;
        }

        .mobileGallery {
            display: block !important;
        }

    }

    .resumeList {

        display: block;

        li {
            margin: 0 !important;
            text-align: center !important;
            width: 100% !important;
            border: 1px solid black !important;
            border-bottom: 1px solid white !important;
            padding-top: 10px !important;
            margin-left: 0 !important;
            padding-left: 0 !important;

            .title {

                opacity: 1 !important;
                display: block !important;
            }

            .company {
                font-weight: bold;
            }
        }


    }

    .contactFooter {
        .coFlag {
            display: none;
        }

        .copyright {
            margin-top: 15px;
            padding-top: 25px;
            position: relative !important;
            text-align: center !important;
            border-top: 2px solid white;
            width: 100% !important;
            right: initial !important;
        }

        ul {
            display: block !important;
            margin: 0 !important;
            padding: 0 !important;

            li {
                width: 100%;
                text-align: center !important;
                margin-left: 0 !important;
            }
        }

        #contactMe {
            font-size: 65px;
            margin-bottom: 50px !important;
        }
    }

    .portfolioPage {
        .filterPortfolioList {
            display: block !important;

            li {
                float: unset;
            }
        }
    }


    .mobileProjectNav {
        display: flex !important;
        width: 100% !important;

    }

    .infoStack {
        align-content: center !important;

        p {
            text-align: center !important;
        }

        .MuiBox-root {
            display: flex;
            justify-content: center !important;
        }
    }

    .largeGalleryNav {
        .navBtns {
            display: none !important;
        }
    }
}


@media (max-width: 600px) {

    .galleryDialog {
        .modal-title {
            font-size: 32px;
        }

        #headerInfo {
            position: relative !important;
        }
    }

    h1 {
        font-size: 80px !important;
        margin-bottom: 10px !important;
    }

    .menu-links {
        justify-content: center !important;
    }

    .menuNav {

        .headerNavTitle {
            justify-content: center !important;
        }


        #menuText {
            font-size: 32px !important;
            width: 100% !important;
            margin: 25px 0 !important;
        }

        .currentStrip {
            font-size: 16px !important;
        }
    }

    .gallery-box-list {
        .title {
            font-size: 28px !important;
        }
    }

    .Home {
        padding-top: 15%;

        .subHeader {
            height: 180px !important;
        }



        .smallHeader {
            font-size: 50px !important;
        }

        .bigBanner {
            font-size: 36px !important;
            line-height: initial;
        }

        .home-section {
            h2 {
                &.word {
                    font-size: 4rem !important;
                    line-height: 65px !important;

                    &.Multidisciplinary {
                        font-size: 52px !important;
                    }

                    &.Knife {
                        font-size: 52px !important;
                    }

                }


            }

            h3 {
                font-size: 42px;
            }


        }
    }
}


@media (max-width: 900px) {


    .ContactPage {
        .outlinedBtn {
            width: 100% !important;
        }

        .contactStack {

            .contactTitle,
            h2 {
                font-size: 36px !important;
                margin: 0 !important;

                h3 {
                    font-size: 42px !important;
                }
            }
        }
    }
}



@media (max-width: 500px) {
    h1 {
        font-size: 62px !important;
    }





    .headerNavTitle {
        font-size: 18px !important;
    }


    .Home {
        .subHeader {
            height: 145px !important;
        }

        .home-section h2.word {
            font-size: 3rem !important;
            line-height: 42px !important;

            &.Multidisciplinary {
                font-size: 38px !important;
            }

            &.Knife {
                font-size: 38px !important;
            }

        }

        #about {
            width: 95% !important;

        }

        .smallHeader,
        .word {
            font-size: 32px !important;
            line-height: 34px !important;
        }

        h3 {
            font-size: 48px !important;
            line-height: 42px !important;
        }

        .jackCard {
            height: 200px !important;
            width: 200px !important;
            left: -90px !important;
        }

        .queen {
            bottom: -30px !important;
            right: -110px !important;
            height: 250px !important;
            width: 250px !important;
        }
    }

    .AboutPage {
        h2 {
            font-size: 35px !important;
        }

        h3 {
            font-size: 32px !important;
        }

        p {
            width: 100% !important;
            font-size: 24px !important;
            margin-bottom: 0 !important;
        }

        .serviceChip {
            font-size: 32px !important;
            padding: 30px !important;
            margin: 5px;
        }
    }

    .resume {
        .resumeList {
            li {
                font-size: 24px;
                padding-bottom: 10px !important;
            }
        }
    }
}