.Resume {
    text-align: center;


    header {
        width: 100%;
        float: left;
        position: relative;

        .links {
            width: auto;
            position: absolute;
            right: 0;

            a {
                text-decoration: none;
                color: black;
                font-size: 12px;
                text-transform: uppercase;
            }
        }
    }

    .container {
        padding-top: 100px;

        .jobList {
            width: 75%;
            margin: auto;
            margin-top: 25px;


            .jobListItem {
                width: 100%;
                text-align: left;
                padding-bottom: 75px;
                border-bottom: 1px solid lightgray;
                padding-top: 25px;

                .title {
                    font-weight: bold;
                    font-size: 16px;

                }

                .dates {
                    font-size: 12px;
                }


                #jobDescList {
                    padding: 0;
                    margin: 0;
                    text-align: left;

                    li {
                        padding: 0;
                        margin: 0;
                        text-align: left;
                    }
                }
            }
        }


    }
}

footer {
    background-color: black;
    color: white;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 5;


    .links {
        width: auto;
        padding-right: 15px;
        // position: absolute;
        // right: 0;

        a {
            text-decoration: none;
            color: white;
            font-size: 12px;
            text-transform: uppercase;
        }
    }
}