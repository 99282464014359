@import './mediqqueries.scss';

:root {
  --color-text: navy;
  --color-bg: papayawhip;
  --color-bg-accent: #ecdcc0;
  --size: clamp(10rem, 1rem + 40vmin, 30rem);
  --gap: calc(var(--size) / 14);
  --duration: 15s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}


* {
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */

}


#root {
  height: 100%;
  width: 100%;

}

body,
html {
  height: 100%;
  width: 100%;
  cursor: url('../images/arrow.png'), default;
}

.btnLink {
  font-size: 24px;
  border: 2px solid white;
  padding: 10px 25px;
  margin-top: 50px;
  background-color: white;
  color: black !important;
  text-decoration: none;

  &:hover {
    background-color: black;
    color: white !important;
    font-style: italic;
  }

  &.inverse {
    border: 2px solid black;
    color: black !important;
    background-color: white !important;

    &:hover {
      background-color: black !important;
      color: white !important;
      font-style: italic;
    }

  }
}

.outlinedBtn {
  display: none !important;
  border: 2px solid black !important;
  color: black !important;
  font-family: 'Futura' !important;

  width: 30% !important;
  border-radius: 25px !important;
  pointer-events: auto;
  cursor: pointer;
  margin-top: 15px !important;

  &:hover {
    background-color: black !important;
    color: white !important;
  }
}

.modal-title {
  font-family: 'Futura';
  font-weight: bold;
  font-size: 42px;
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

h1 {
  font-size: 100px;
  text-transform: uppercase;
}

h2 {
  font-size: 88px;
}

h3 {
  font-size: 65px;
}



.App {
  text-align: center;
  font-family: "Futura", 'Roboto', 'Helvetica', sans-serif;

  .header {
    padding-top: 50px;

    a {
      text-decoration: none;
      color: black;

      &:hover {
        color: blue;
      }
    }
  }

  .menuNav {
    position: fixed;
    height: 70px;
    min-height: 50px;
    background-color: black;
    color: white;
    width: 100%;
    z-index: 100;
    padding-right: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    .headerNavTitle {
      font-size: 28px;
      font-weight: bold;
      // margin: 15px 0;
      display: flex;
      justify-content: flex-start;
    }

    .headerLink {
      padding-left: 25px;
      text-align: left;

      a {
        text-decoration: none;
        color: white;
      }
    }

    .mobileHeaderMenu {
      display: none;

      margin-top: 25px;

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          margin: 5px;


          a {

            font-family: "Futura Light";
            font-weight: 100;
            letter-spacing: 1px;
            font-size: 18px;
            opacity: 1;

          }
        }
      }

    }

    .menu-links {

      .menu-link-anchor {
        &.active {
          border-bottom: 2px solid white;
        }
      }

      a {
        color: white;
        letter-spacing: 1px;
        text-decoration: none;
        text-transform: none;
        border-radius: 0;
        font-family: "Futura";
        font-size: 14px;
        padding: 10px;


        &:hover {
          background-color: white;
          color: black;
          font-weight: bold;
          border-bottom: 2px solid white;
        }
      }
    }

    li {
      padding: 3px 0;
      font-size: 18px;
      cursor: pointer;

      &:hover {
        background-color: white;
        color: black;
        font-weight: bold;
      }
    }
  }
}


.galleryDialog {


  .mobileProjectNav {
    position: absolute;
    bottom: 0;
    z-index: 10;
    background-color: white;
    right: 0;
    display: none;
  }

  .project-holder {
    position: relative;
    font-family: "Futura";
    font-size: 18px;


    #headerInfo {
      position: sticky;
      top: -15px;
      background-color: white;
      padding-bottom: 15px;

      .MuiChip-root {
        font-family: 'Futura';
        letter-spacing: 2px;
        text-transform: uppercase;
        border: 1px solid black;
      }

      a {
        text-decoration: none;
        background-color: black;
        color: white;
        padding: 10px 25px;

        &:hover {
          border: 2px solid black;
          color: black;
          font-style: italic;
          background-color: white;

        }
      }
    }

    .gallery {
      width: 100%;

      .project-image {
        margin: auto;

        img {
          max-width: 960px;
        }
      }
    }

    .galleryVid {
      video {
        max-width: 960px;
        max-height: 540px;
      }
    }
  }
}


footer {
  position: relative;
}


.contactFooter {
  background-color: black;
  color: white;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 5rem;
  margin: 0;

  .clientChip {
    border: 1px solid white;
    font-size: 10px;

    padding: 3px;
    text-transform: uppercase;
    letter-spacing: 3px;
    border-radius: 3px;

    text-align: center;

    background-color: white;
    color: black;
  }

  .coFlag {
    position: relative;
    background: #cccccc;

    #flag {
      height: 12px;
      width: 25px;
      margin: 0 auto;
      background: #ffffff;
      position: absolute;
      z-index: 100;
    }

    #flag:before {
      background: #081C59;
      height: 7px;
      width: 25px;
      content: "";
      position: absolute;
      z-index: 100;
    }

    #flag:after {
      background: #081C59;
      height: 7px;
      width: 25px;
      content: "";
      position: absolute;
      z-index: 100;
      top: 12px;
    }

    #circle {
      border-right: 6px solid red;
      border-left: 6px solid red;
      border-top: 6px solid red;
      border-bottom: 6px solid red;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 10px;
      position: absolute;
      z-index: 100;
      top: 3px;
      left: 3px;
    }

    #circle:after {
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      border-radius: 10px;
      position: absolute;
      z-index: 100;
      content: "";
      border: 3px solid yellow;
      left: -3px;
      top: -3px;
    }

    #circle:before {
      position: absolute;
      left: -3px;
      top: -3px;
      z-index: 100;
      content: "";
      border-right: 6px solid white;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-left: 3px solid transparent;
    }


  }


  .footerInfo {
    border-top: 2px solid white;
    padding-top: 25px;
    position: relative;


    #siteTag {
      font-size: 18px;
      margin: 0;
      padding: 0;
      line-height: 12px;


    }


    .copyright {
      position: absolute;
      top: 25px;
      right: 25px;
      text-align: right;
      line-height: 70px;


    }
  }

  ul {
    list-style-type: none;
    font-size: 3rem;
    margin: 0;

    li {
      text-align: left;
      margin: 0;
      font-size: 18px;
      padding: 5px;
    }
  }

  a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    height: 100%;
    width: 100%;
    padding: 3px;

    &:hover {
      background-color: white;
      color: black;
    }
  }
}



.marquee {
  --gap: 1rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  margin-top: 25px;
  margin-bottom: 50px;
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  font-size: 82px;
  font-weight: bold;
}

.marquee__item {
  display: inline-block;
  font-size: 82px;
  margin-right: 25px;

  cursor: pointer;
  margin: 0;

  a {
    padding: 5px 25px;
    font-size: 82px !important;
  }

  &:hover {
    background-color: white;
    color: black;
    font-weight: bold;
    font-style: italic;

  }
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  gap: var(--gap);
  animation: scroll var(--duration) linear infinite;
  -webkit-animation: scroll var(--duration) linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

@-webkit-keyframes scroll {
  from {
    -webkit-transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(calc(-100% - var(--gap)));
  }
}

@-webkit-keyframes swinging {
  0% {
    -webkit-transform: rotate(10deg);
  }

  50% {
    -webkit-transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(10deg);
  }
}

@keyframes swinging {
  0% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(10deg);
  }
}

.swingimage {
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-animation: swinging 3.5s ease-in-out forwards infinite;
  animation: swinging 3.5s ease-in-out forwards infinite;
}

.fade-container {
  display: inline-block;
  font-weight: bold;
  position: relative;
  height: 1em;
}

.fade-container span {
  position: absolute;
  opacity: 0;
  animation: fadeInOut 16s linear infinite;
  -webkit-animation: fadeInOut 16s linear infinite;
}

.fade-container span:nth-child(1) {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}

.fade-container span:nth-child(2) {
  animation-delay: 2s;
  -webkit-animation-delay: 2s;
}

.fade-container span:nth-child(3) {
  animation-delay: 4s;
  -webkit-animation-delay: 4s;
}

.fade-container span:nth-child(4) {
  animation-delay: 6s;
  -webkit-animation-delay: 6s;
}

.fade-container span:nth-child(5) {
  animation-delay: 8s;
  -webkit-animation-delay: 8s;
}

.fade-container span:nth-child(6) {
  animation-delay: 10s;
  -webkit-animation-delay: 10s;
}

.fade-container span:nth-child(7) {
  animation-delay: 12s;
  -webkit-animation-delay: 12s;
}

.fade-container span:nth-child(8) {
  animation-delay: 14s;
  -webkit-animation-delay: 14s;
}

@keyframes fadeInOut {

  0%,
  12.5%,
  100% {
    opacity: 0;
  }

  6.25%,
  9.25% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInOut {

  0%,
  12.5%,
  100% {
    opacity: 0;
  }

  6.25%,
  9.25% {
    opacity: 1;
  }
}



// RESUME SECTION

.resume {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;

  .resumeList {
    width: 100%;
    margin: 0;
    padding: 0;

    .resumeBtn {
      // float: left;
      width: 33.33333%;
      height: 80px;
      text-align: center;
      font-size: 28px;
      line-height: 32px;
      border: 1px solid white;
      border-radius: 50px;
      margin: 10px;
      padding-top: 25px;
      padding-left: 25px;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      .title {
        font-size: 24px;
        opacity: 0;
        display: none;
      }

      .arrow {
        position: absolute;
        color: black;
        text-decoration: none;
        bottom: -50px;
        right: 25px;
        text-align: right;
      }

      &:hover {
        .title {
          opacity: 1;
          transition: all .1s;
          display: block;
          font-style: italic;
        }

        .arrow {
          display: block;
          bottom: 0px;
          transition: all .5s;

          svg {
            font-size: 2em;
          }
        }

        .company {
          font-weight: bold;
        }

        background-color: white;
        color: black;

        transition: all .1s;
        padding-top: 10px;

      }
    }
  }
}