@import './mediqqueries.scss';

.Home {
    padding-top: 5%;

    .home-section {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: column;
        min-height: calc(100vh - 65px);
        text-transform: uppercase;

        h2 {
            &.word {
                font-size: 9em;

                font-weight: bold !important;
                font-family: "Futura Bold";
                line-height: 135px !important;

                &.Multidisciplinary {
                    font-size: 125px !important;
                }

                &.Knife {
                    font-size: 125px !important;
                }
            }
        }

        .smallHeader {
            font-size: 75px;
            margin-bottom: 12px;
        }
    }

    .subHeader {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 330px;
    }

    .bigBanner {
        padding-top: 50px;
        background-color: black;
        min-height: 100vh;
        color: white;
        font-size: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: column;
        text-transform: uppercase;
        overflow: hidden;

        a {
            color: white;
            text-decoration: none;
        }

        &.quoteContainer {
            position: relative;
            background-color: black;
            transition: 1s ease-in-out all;
            text-align: center;
            list-style: none;
            min-height: 65vh;
            height: 65vh;
            padding-bottom: 100px;

            font-family: "Futura Bold";

            .quote {
                // text-transform: uppercase;
                text-align: center;
                background: transparent;
            }

            .jackCard {
                position: absolute;
                left: -225px;
            }

            .queen {
                position: absolute;
                right: -225px;
            }

        }
    }


    #about {
        width: 85%;
        padding-bottom: 100px;

        h3 {
            margin-top: 15px
        }

    }

    .word {
        transition: all .25s ease-in-out;
        opacity: 1;
    }

    .fade-in {
        opacity: 1;
        // margin-top: 150px;
        font-size: 9em;
    }

    .fade-out {
        opacity: 0;
        // margin-bottom: 150px;
        font-size: 7em !important;
    }


    .mobileGallery {
        display: none;

        h3 {
            padding-top: 25px;
            position: relative;
        }

        .galleryList {

            .gallery-box-list {
                height: 100px;
                border-bottom: 1px solid white;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;

                .title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    font-size: 42px;
                }

                &:hover {
                    background-color: white;
                    color: black;
                    font-weight: bold;
                }
            }
        }
    }

    .gallery {
        min-height: 600px;
        width: 95%;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        transform: translate(18%, 0);

        .gallery-box {
            height: 550px;
            width: 175px;
            margin: 0 15px;
            display: inline-block;
            background-color: white;
        }
    }

    .box {
        position: relative;
        margin-top: 100px;
        margin-left: 25px;
        margin-right: 25px;
        min-width: 400px;
        max-height: 750px;
        height: 75%;
        overflow: hidden;
        transition: all .5s;
        opacity: 1;
        cursor: pointer;

        .projectBGImage {
            position: absolute;
            background-size: cover;
            background-position: center center;
            position: relative;
            height: 100%;
            width: 100%;
            overflow: hidden;
        }

        .title {
            position: absolute;
            z-index: 5;
            margin: auto;
            height: 100%;
            width: 100%;
            background-color: transparent;
            opacity: 0;
            font-size: 48px;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            flex-direction: column;
        }

        h4 {
            font-size: 36px;
            font-weight: normal;
            margin: 0;
            width: 98%;
        }

        p {
            margin: 0;

            .MuiChip-root {
                color: white;
                font-family: 'Futura';
                letter-spacing: 2px;
            }
        }

        &:hover {
            border: 8px solid white;
            background-color: black;

            .projectBGImage {
                opacity: .1;
            }


            .title {
                opacity: 1;
            }
        }

    }

    #selectedWorks {
        text-transform: initial;
    }


}


#services {
    .serviceContainer {

        display: flex;
        justify-content: center;
        padding-bottom: 50px;


    }

    .serviceLines {
        height: 250px;
        display: none;
    }

    .serviceCard {
        margin: 10px;
        border-radius: 25px;
        border: 3px solid white;
        cursor: pointer;
        transition: all .5s;
        padding: 25px;
        height: 500px;

        ul {
            list-style-type: none;
            font-size: 21px;
            margin: 0;
            padding: 0;
            margin-top: 25px;

            li {
                padding: 5px 0;
            }
        }

        .serviceIcons {
            height: 250px;
        }

        .serviceLines {
            display: none;
        }

        h4 {}

        &:hover {
            border: 3px solid black;

            .serviceIcons {
                display: none;
            }

            .serviceLines {
                display: block;
            }
        }
    }

    h4 {
        font-size: 2rem;
        text-transform: uppercase;
    }

    #wireframeImg {
        margin-top: 25px;
        margin-left: 0px;
    }

    .mobileTitle {
        display: none;
        position: absolute;
        // top: 100%;
        bottom: 200px;
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.logosTitle {
            top: 95%;
        }

        &.gd,
        &.motionDesign,
        &.artTitle,
        &.ux {
            top: 70%;
        }


        &.frontEnd {
            top: 55%;
        }
    }
}

#service {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

#text {
    width: 50%;
    min-height: 100vh;
    opacity: 1;
}


.bg-red-500 {
    height: 15rem;
    width: 15rem;

    position: relative;
    opacity: 1;
    margin: auto;

    &#graphics,

    &#logo,
    &#arts {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}

#user,
#user-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

// 

#graphicsText,
#codingText {
    font-size: 16px;
    position: absolute;
    font-family: 'Courier New', Courier, monospace;
}

#codingText {
    text-align: left;
    position: absolute;
    bottom: 50px;
}

#codingOutput {
    top: 0;
    left: 0;
    border: 1px solid black;
    border-radius: 25px;
    height: 250px;
    width: 100%;
    position: absolute;
}

#headerPlaceholder {
    min-height: 25px;
    width: 145px;
    position: absolute;
    top: 80px;
    left: 80px;
    background-color: gray;
}

#userPic {
    position: absolute;
    left: 30px;
    width: 195px;
    top: 75px;
}

#lorem {
    position: absolute;
    right: 50px;
    width: 20%;
    top: 135px;
    font-size: 10px;
    text-align: left;
}

#logo-grid {
    position: absolute;
    z-index: -1;
}

.dot {
    position: absolute;
    top: 100px;
    left: 122px;
    height: 0;
    width: 0;
    background-color: black;

    &#dot1 {
        top: 50px;
        left: 55px;
        height: 15px;
        width: 15px;
    }

    &#dot2 {
        top: 110px;
        left: 200px;
    }

    &#dot3 {
        top: 215px;
        left: 55px;
    }
}

.menuBar {
    position: absolute;
    border: 1px solid gray;
    width: 45px;
    height: 125px;
    top: 80px;
    z-index: 5;
    left: 30px;

    &#fakeNav1 {
        opacity: 0;
    }

    &#fakeNav2 {
        opacity: 0;
    }
}

#uiBtn {
    position: absolute;
    border-radius: 25px;
    font-size: 10px;
    padding: 3px;
    top: 165px;
    right: 25px;
    background-color: gray;
    width: 40px;
    height: 10px;
}

#computerBar {
    position: absolute;
    width: 100px;
    height: 15px;
    background-color: black;
    top: 220px;
    left: 80px;
    border-radius: 25px;
}

.placeholder {
    position: absolute;
    top: 60px;
    left: 118px;

    &.plce2 {
        left: 90px;
    }

    &.plce3 {
        left: 165px;
    }
}

#slider {
    position: absolute;

    left: -70px;
    top: 40px;
}

#ball {
    position: absolute;
    height: 15px;
    width: 15px;
    background-color: black;
    border-radius: 100%;
    top: 37px;
    left: 55px
}

#palatte {
    position: absolute;
    z-index: -1;
    top: 30px;
}

#brush {
    position: absolute;
}

#graphicsText {
    position: absolute;
    top: 50px;
    left: 75px;
    font-size: 55px;
}

#gdLayout {
    position: absolute;
    top: 65px;
}


.textRectangle,
.textRectangle2 {
    position: absolute;
    height: 6px;
    width: 95px;
    left: 82px;
    background-color: black;



    &#text2,
    &#text6 {
        top: 182px;
    }

    &#text3,
    &#text7 {
        top: 192px;
    }

    &#text4,
    &#text8 {
        top: 202px;
    }

    &#text5,
    &#text9 {
        top: 255px;
    }


}

.textRectangle2 {
    left: 215px;
}

#code {
    overflow: hidden;
    padding-top: 50px;
}

#codeText {

    text-align: left;


}

.highlight {


    width: 0;


}

.bar {
    background-color: black;
    width: 125px;
    height: 15px;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 32px;
    text-align: left;
}

.helloWorld {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.codeRectangle {
    position: absolute;
    height: 8px;
    width: 0px;
    left: 85px;
    margin-bottom: 15px;
    background-color: black;

    &#code1 {
        top: 5px;
    }


    &#code2 {
        top: 25px;
    }

    &#code3 {
        top: 35px;
    }

    &#code4 {
        top: 45px;
    }

    &#code5 {
        top: 55px;
    }

    &#code6 {
        top: 65px;
    }

}




:root {
    --hue: 220deg;
    --base-bg-sat: 20%;
    --base-bg-lum: 12%;
    --base-bg: hsl(var(--hue), var(--base-bg-sat), var(--base-bg-lum));
    --base-fg-sat: 50%;
    --base-fg-lum: 80%;
    --base-fg: hsl(var(--hue), var(--base-fg-sat), var(--base-fg-lum));
    --filter-fg: saturate(100%) brightness(100%);
    --module-bg-sat: 18%;
    --module-bg-lum: 27%;
    --module-bg: hsl(var(--hue), var(--module-bg-sat), var(--module-bg-lum));
}


div.notice {
    font-size: 4em;
    text-align: center;
    margin-top: 2em;
}

.world {
    position: absolute;
    fill: #000;
    width: 1em;
    height: 1em;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    white-space: nowrap;
    border: 2px solid #000;
    box-sizing: border-box;
    background-image: url(#icon-world);
    top: 35%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
}

.world svg {
    width: 4em;
    height: 1em;
    margin-top: calc(-2px + -0.05em);
    display: inline;
    animation: world-scroll 25s linear infinite;
}

@keyframes world-scroll {
    from {
        margin-left: -2.75em;
    }

    to {
        margin-left: -1em;
    }
}