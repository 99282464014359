.ErrorPage {
    height: 100vh;
    padding-top: 65px;

    h1 {
        margin-bottom: 125px;
    }

    a {
        border: 2px solid black;
        padding: 15px;

        text-decoration: none;
        color: black;

        &:hover {
            background-color: black;
            color: white;
        }
    }
}