.portfolioPage {
    padding-bottom: 50px;

    .filterPortfolioList {

        list-style-type: none;
        display: inline-block;

        li {
            float: left;
            margin: 0 25px;
            padding: 10px;
            border: 2px solid white;
            cursor: pointer;

            &:hover {
                border: 2px solid black;
            }

            &.active {
                background-color: black;
                color: white;
                font-style: italic;
                pointer-events: none;
            }
        }
    }

    .gallery {
        margin: auto !important;
        transform: translate(0, 0);
        justify-content: center;

        .box {
            cursor: pointer;

            &:hover {
                border: 8px solid black;
            }

            .MuiChip-root {
                font-family: 'Futura';
                letter-spacing: 2px;
                text-transform: uppercase;
                border: 1px solid black;
                color: black;
            }

        }

        .gallery-box {
            width: 25% !important;
            margin-top: 15px;
            margin-bottom: 15px;
            background-color: white;
        }
    }
}