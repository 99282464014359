.Services {
    width: 75% !important;
    margin: auto;

    .serviceBox {
        border-top: 2px solid black;
        min-height: 265px;
        cursor: pointer;
        transition: all .5s;

        .serviceList {
            display: none;
            margin-bottom: 25px;

        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            font-size: 24px;
            margin-top: 25px;
        }

        h2 {
            text-transform: uppercase;
            font-size: 72px;
            margin: 15px 0;
        }


        &:hover {
            background-color: black;
            color: white;
            height: 600px;

            .serviceList {
                display: block;
            }

            .serviceProj {
                display: block;
            }
        }


        .serviceProj {
            display: none;
            position: relative;
            height: 75px;
            width: 125px;
            margin: 10px;
            transition: all 1s;

            .projTitle {
                display: none;
                position: absolute;
                z-index: 5;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .projectBGImage {
                height: 100%;
                width: 100%;
                background-size: cover;
                background-position: center;
            }

            &:hover {
                height: 100px;
                width: 150px;

                .projectBGImage {
                    opacity: .2;

                }

                .projTitle {
                    display: block;
                    opacity: 1;

                }

                .projHolder {
                    margin-top: 25px;
                    margin-bottom: 25px;
                }
            }
        }
    }
}